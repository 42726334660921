import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ImageCard from "../components/ImageCard"
import SocialMedias from "../components/SocialMedias"

export default function Home({ data }) {
  // Use a random image from the set of images found in home_icons
  const image_nodes = data.allFile.nodes;
  const random = Math.floor(Math.random() * image_nodes.length);  //Generates a random integer based on the number of image files available
  const image = getImage(image_nodes[random]);
  // Access the markdown data
  const projects = data.projects.nodes;  
  
  return(
    <Layout>
      <section className="">
        <div className="row justify-space-between">
          <div className="col-6-lg col-12-xs">
            <h2>Jelly Fox Studios</h2>
            <h3>Animation & Storytelling</h3>
            <br />
            <h4>Welcome!</h4>
            <p>We will do all we can to get the Unknown to be known! To give new content creators a way to get their material out ther in a reputable and organized fashion. By combining the talents of these talented individuals, our hope is that we will be able to help them get noticed and that their true potential gets realized!</p>
            <p>We will not hold any exclusive rights as a company over any works submitted but reserve the right to retain copies should anything happen to the final product where it needs restored or fixing.</p>
            <br />
            {SocialMedias()}
          </div>
          <GatsbyImage image={image} alt={image_nodes[random].name} className="col-6-lg col-12-xs"/>
        </div>
        <br />
        <h1>Our Projects</h1>
            <div className='row'>
              {projects.map(project => (
                ImageCard(
                  "/projects/" + project.frontmatter.slug,
                  project.id,
                  project.frontmatter.img,
                  project.frontmatter.title,
                  project.frontmatter.categories
                )
              ))}
            </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query homePage {
    allFile(filter: {relativeDirectory: {eq: "home_icons"}}) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
    projects: allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}) {
      nodes {
        id
        frontmatter {
          slug
          title
          categories
          img {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, height: 400)
            }
          }
        }
      }
    }
  }
`