import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

export default function SocialMedias(email = false) {
    const data = useStaticQuery(graphql`
    {
        site {
            siteMetadata {
                contact
                sm_minds
                sm_twitter
                sm_youtube
                sm_facebook
            }
        }
    }
    `)
    const { contact, sm_minds, sm_twitter, sm_youtube, sm_facebook } = data.site.siteMetadata;

    return (
    <div>
        { email && <div>
            <p>Have something you wish to discuss with us? You can reach us by email:</p>
            <h4>{contact}</h4>
            <p>You can also find us on various social media:</p>
            <ul>
                <li>{sm_minds}</li>
                <li>{sm_twitter}</li>
                <li>{sm_youtube}</li>
                <li>{sm_facebook}</li>
            </ul>
            </div>
        }
        {!(email) && <div>
            <p>Follow us on various social media:</p>
            <ul>
                <li>{sm_minds}</li>
                <li>{sm_twitter}</li>
                <li>{sm_youtube}</li>
                <li>{sm_facebook}</li>
            </ul>
        </div>
        }
    </div>
    )
}